.trend {
  padding: 3rem 7rem;
  /* remove below once its done, this for coming soon */
  position: relative;
  z-index: 2;
}

.trend-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0px 0 10px;
}

.trend-header h1 {
  color: white;
  font-size: 45px;
}

.trend-header button {
  background: #0a3281;
  border-radius: 13px;
  color: #ffffff;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  padding: 0.5rem 1rem;

  border: none;
  outline: none;
  cursor: pointer;
  min-width: 150px;
}

/* .trend p {
  color: var(--color-text);
  font-size: 20px;
  margin: 8px 0px;
  line-height: 26px;
} */

.trend-container {
  filter: blur(10px);
  padding: 2rem 0rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.trend-container > div {
  flex: 0 1 calc(50% - 10px);
  margin-bottom: 20px;
  text-align: center;
}

.trend-container img {
  width: 100%;
}

.trend-container p {
  color: white;
  font-size: 30px;
  font-weight: 200;
}

.trend-container p2 {
  color: gray;
  font-size: 17px;
}

.trend-header button:hover {
  background: white;
  color: #1a0c79;
}

@media screen and (max-width: 800px) {
  .trend {
    padding: 0rem 2rem;
  }
  .trend-header button {
    display: none;
  }
}
