nav {
  height: 100vh;
  position: sticky;
  top: 0;
  background-color: #191d21;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

nav li,
.navbar-title-container {
  padding: 10px 25px;
}
.navbar-title-container {
  display: flex;
}
.navbar-title-container img {
  margin: -42px 0px;
  width: 130px;
  height: 130px;
}
.navbar-menuitems {
  /* moves the menu items up in the container */
  margin-bottom: 280px;
  font-size: 20px;
}

.navbar-menuitems li {
  display: flex;
  align-items: center;
}

.navbar-menuitems a:hover {
  color: #2451f0;
}
.navbar-ending span {
  border-left: solid 1px rgb(8, 8, 218);
  font-size: 15px;
  padding: 0px 5px;
  line-height: 23px;
  margin: 0px 9px;
}
.span-comingsoon {
  color: rgb(178, 178, 178);
  font-size: 10px;
  background-color: rgb(47, 47, 47);
  padding: 4px 4px;
  border-radius: 10px;
  margin: 0px 5px;
  vertical-align: middle;
}
