.level-tracker {
  background: #2451f0;
  border-radius: 25px;
  padding: 16px;
  margin: 16px 0px;
  display: flex;
  justify-content: space-between;
}

.level-tracker span {
  color: rgb(196, 196, 196);
}

.level-counter {
  font-size: 2rem;
  text-align: right;
}

.friends-list,
.recent-teammate-list {
  padding: 16px;
}

.friends-list-container h3 {
  margin-bottom: 16px;
  font-size: 12px;
}
