.news {
  padding: 2rem 4rem;
  overflow: hidden;
}

.news h1 {
  color: white;
  padding: 10px 0px;
  font-size: 45px;
}

.news-header {
  text-align: center;
  margin-bottom: 120px;
}

.news-header img {
  width: 90%;
}

.news-latest {
  color: var(--color-text);
  font-size: 25px;
}

.news-latest-container {
  margin-top: 30px;
  cursor: pointer;
  width: 100%;
  margin-bottom: 400px;
}

.news-medium-articles {
  display: grid;
  flex-wrap: wrap;
  grid-gap: 20px;
  grid-template-columns: repeat(3, 1fr);
}

.news-latest-container h1 {
  font-size: 30px;
}

.news-latest-container h1:hover {
  text-decoration: underline;
  color: rgb(21, 44, 127);
}

@media screen and (max-width: 800px) {
  .news {
    padding: 0rem 1rem;
  }
  .news-latest-container {
    display: grid;
  }
}
