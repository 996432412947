.about {
  padding: 2rem 4rem;
  overflow-x: hidden;
}

.about-header {
  color: white;
  text-align: center;
  margin-top: 200px;
  position: relative;
  z-index: 3;
}

.about-header h1 {
  font-size: 90px;
  font-weight: 400;
}

.about-header p {
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
}

.about-images {
  display: flex;
  margin-bottom: -180px;
}

.blue {
  position: absolute;
  bottom: 11%;
  left: -48%;
  z-index: 2;
  width: 100%;
  filter: drop-shadow(0 0 55px #485cfb);
  user-select: none;
}

.red {
  position: relative;
  bottom: 90px;
  left: 44%;
  z-index: 2;
  transform: rotate(-43.35deg);
  width: 100%;
  filter: drop-shadow(0 0 55px #ff2f2f);
  user-select: none;
}

.about-details {
  color: var(--color-text);
  margin: 15px;
  padding: 20px 50px;
  border-left: solid 3px white;
  max-width: 1100px;
}

.about-details h1 {
  color: white;
  font-size: 50px;
  margin-bottom: 10px;
}

.about-details p {
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
}

.about-details p:nth-child(2) {
  margin-bottom: 150px;
}

.about-roadmap {
  padding-top: 130px;
  justify-content: center;
  display: flex;
  color: white;
  margin-bottom: 230px;
}

.about-roadmap p {
  text-align: center;
  border: 1px solid rgb(199, 199, 199);
  box-sizing: border-box;
  border-radius: 3px;
  padding: 70px 50px;

  font-size: 30px;
}

.about-roadmap-hr {
  justify-content: center;
  margin: 90px 30px;
  align-items: center;
  height: 2px;
  border-right: 230px solid #ccc;
}

.about-description {
  color: var(--color-text);
  margin: 15px;
  padding: 10px 50px;
  border-left: solid 3px white;
  max-width: 1100px;
  margin-bottom: 90px;
}

.about-description h1 {
  color: white;
  font-size: 50px;
  margin-bottom: 10px;
}

.about-description p {
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
}

@media screen and (max-width: 800px) {
  .about {
    padding: 0rem 0.5rem;
  }
  .about-roadmap {
    display: grid;
  }
  .about-roadmap-hr {
    transform: rotate(90deg);
  }
}
