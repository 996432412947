.docs {
  padding: 2rem 4rem;
  overflow-x: hidden;
}

.docs-header {
  color: white;
  text-align: center;
  margin-top: 200px;
  position: relative;
  z-index: 3;
}

.docs-header h1 {
  font-size: 90px;
  font-weight: 400;
}

.docs-header p {
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
}

.docs-images {
  margin-bottom: -200px;
}

.docs-details {
  color: var(--color-text);
  margin: 15px;
  padding: 10px 50px;
  border-left: solid 3px white;
  max-width: 1100px;
}

.docs-details h1 {
  color: white;
  font-size: 50px;
  margin-bottom: 10px;
}

.docs-details p {
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
}

.docs-details-whitepaper {
  background: #2b2e37;
  border-radius: 13px;
  color: #5a5959;
  font-size: 20px;
  line-height: 50px;
  padding: 0.5rem 0rem;
  margin-top: 23px;
  margin-bottom: 150px;
  width: 100%;
  max-width: 400px;

  border: none;
  outline: none;
  cursor: pointer;
}

.docs-details-litepaper {
  background: #2b2e37;
  border-radius: 13px;
  color: #5a5959;
  font-size: 20px;
  line-height: 50px;
  padding: 0.5rem 0rem;
  margin-top: 23px;
  width: 100%;
  max-width: 400px;

  border: none;
  outline: none;
  cursor: pointer;
}

.docs-legal {
  color: var(--color-text);
  margin: 130px 15px;
  padding: 10px 50px;
}

.docs-legal h1 {
  color: white;
  font-size: 50px;
  margin-bottom: 10px;
}

.docs-legal p {
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
}

.docs-description {
  color: var(--color-text);
  margin: 270px 15px;
  /* right one after legal comes back margin: 15px; */
  padding: 10px 50px;
  border-left: solid 3px white;
  max-width: 1100px;
  margin-bottom: 90px;
}

.docs-description h1 {
  color: white;
  font-size: 50px;
  margin-bottom: 10px;
}

.docs-description p {
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
}

@media screen and (max-width: 800px) {
  .docs {
    padding: 0rem 0.5rem;
  }
  .docs-header h1 {
    font-size: 60px;
  }
}
