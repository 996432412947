/* li,
div {
  color: white;
} */

.recently-played-container {
  margin-bottom: 32px;
}
.recently-played-container h2,
.quick-access-container h2 {
  font-size: 20px;
  margin-bottom: 20px;
}
.recently-played-container .sub-text {
  font-style: italic;
}

.landing-page-container{
  padding-right: 3rem;
}

button {
  margin-right: 16px;
  cursor: pointer;
}
button.primary {
  color: white;
  background-color: #2451f0;
  border: none;
  border-radius: 32px;
  padding: 8px 32px;
}
button.secondary {
  color: white;
  background-color: transparent;
  border: solid 1px #2451f0;
  border-radius: 32px;
  padding: 8px 32px;
}
