.popular {
  padding: 3rem 7rem;
  position: relative;
}

.popular h1 {
  background: linear-gradient(to right, #3e5bcf 9%, #6e44cf 55%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  font-size: 45px;
}

.popular-header {
  user-select: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin: 0px 0 10px;
}

.popular-header button {
  background: #31257d;
  border-radius: 13px;
  color: #ffffff;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  padding: 0.5rem 2.4rem;

  border: none;
  outline: none;
  cursor: pointer;
  min-width: 13%;
}

.popular-header button:hover {
  background: #2a1995;
}

.popular p {
  color: var(--color-text);
  font-size: 20px;
  margin: 10px 0px;
  line-height: 26px;
}

.popular-hr {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1px;
  border: 0;
  border-right: 650px solid #6e44cf;
  margin: 0;
  width: 100%;
}

.popular-container-image {
  transition: filter 0.3s ease;
  margin-bottom: 60px;
}

.popular-container-image:hover {
  filter: brightness(1.2);
  box-shadow: 0 0 50px #5847c5;
}

.popular-container-image img {
  width: 100%;
}

.popular-container-image p {
  font-weight: 800;
}

.popular-container-image p2 {
  text-align: center;
  color: rgb(126, 121, 121);
}

/* replace the popular-container with popular-container-image when this popular section is ready */

@media screen and (max-width: 770px) {
  .popular {
    padding: 0rem 2rem;
  }
  .popular h1 {
    font-size: 40px;
    font-weight: bold;
  }
  .popular p {
    font-weight: bold;
    line-height: 24px;
  }
  .popular-hr {
    display: none;
  }
  .popular-header button {
    display: none;
  }
}

@media screen and (min-width: 1500px) {
  .popular-container {
    justify-content: space-between;
  }
  .popular h1 {
    font-size: 63px;
  }
  .popular p {
    font-size: 26px;
  }
}
