header {
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 50px; */
  /* removed for the toggle navbar menu */
}
.gaming-header {
  padding: 13px 0px;
  width: 90%;
}
.gaming-header-search {
  display: flex;
}
.gaming-header input {
  background-color: rgb(42, 41, 41);
  color: white;
  width: 380px;
  height: 40px;
  border-radius: 15px;
  border: none;
  padding-left: 1.3rem;
  font-size: 1rem;
}
.gaming-header-search-button {
  color: white;
  background-color: #2451f0;
  border: none;
  border-radius: 0px 15px 15px 0px;
  padding: 9px 32px;
  margin: 0px -80px;
}
.searchline {
  font-size: 17px;
}

.gaming-header-login {
}


.ethersButton{
  color: white;
  background-color: #2451f0;
  border: none;
  border-radius: 15px 15px;
  padding: 9px 32px;
}