.level-tracker {
  background: #2451f0;
  border-radius: 25px;
  padding: 16px;

  display: flex;
  justify-content: space-between;
}

.level-counter {
  font-size: 2rem;
  text-align: right;
}

.friends-list-item-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.friends-list-item-container span {
  font-size: 12px;
  padding: 0px 10px;
}

.friends-list-item-info {
  display: flex;
}

.avatar-container img {
  max-height: 42px;
}

button.add-friend-btn {
  border-radius: 4px;
  padding: 7px 10px;
  margin-right: -20px;
}
