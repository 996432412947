.mediumcard {
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.mediumcard:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.mediumcard-thumbnail {
  width: 100%;
  border-radius: 6px;
}

.mediumcard-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
  width: 100%;
}

.mediumcard-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.mediumcard-title {
  font-size: 20px;
}

.mediumcard-subtitle {
  color: #666;
  font-size: 10px;
}
